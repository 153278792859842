<template>
	<div class="call-type">
		<button
			id="call-type-toggle"
			class="call-action"
			aria-haspopup="listbox"
			aria-label="Call filter"
			@click.prevent.stop="onToggleClick"
		>
			Move Call
			<font-awesome-icon
				class="call-type-icon"
				:icon="['fal', show ? 'chevron-up' : 'chevron-down']"
			/>
		</button>
		<transition name="fade">
			<div v-if="show" class="call-type-dropdown" :class="{ show }">
				<div
					id="call-type-list"
					tabindex="-1"
					role="listbox"
					aria-label="Choose a call filter"
					aria-multiselectable="true"
					class="call-type-dropdown-list"
					@click.stop
				>
					<div class="call-type-container">
						<div
							v-if="callTypes.length > 0"
							class="call-type-header"
						>
							Call Types
						</div>
						<div
							v-for="type in callTypes"
							:id="`call-type-${type.id}`"
							:key="type.id"
							class="call-type-item form-check"
						>
							<input
								:id="`call-type-item-${type.id}`"
								v-model="newType"
								type="radio"
								:value="type.id"
								class="form-check-input"
							/>
							<label
								:for="`call-type-item-${type.id}`"
								class="form-check-label"
							>
								{{ type.name }}
							</label>
						</div>
					</div>
					<div class="call-type-submit">
						<button
							type="button"
							:disabled="applyingTypeChange"
							class="btn btn-outline-warning w-100"
							@click.prevent.stop="onTypeChange"
						>
							<div class="btn-ripple"></div>
							<span class="btn-label whitespace-no-wrap m-2"
								>Move Call</span
							>
							<font-awesome-icon
								v-if="applyingTypeChange"
								spin
								fixed-width
								size="1x"
								margin="0 0 0 10px"
								:icon="['far', 'spinner-third']"
							/>
						</button>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>
<script>
import { mapActions } from 'vuex'

/**
 * The escape numeric key code.
 *
 * @type {Number}
 */
const ESCAPE_KEY_CODE = 27

export default {
	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Add the escape keydown event listeners.
		 *
		 * @return {void}
		 */
		addEventListeners() {
			document.addEventListener('click', this.onOutsideClick)
			document.addEventListener('keydown', this.onEscapeKeyDown)
		},

		/**
		 * Determine if the given keycode is the escape key.
		 *
		 * @param {Number} keyCode
		 * @return {Boolean}
		 */
		isEscapeKeyCode(keyCode) {
			return keyCode === ESCAPE_KEY_CODE
		},

		/**
		 * Handle the escape keydown event.
		 *
		 * @returns {void}
		 */
		onEscapeKeyDown(event) {
			if (this.show && this.isEscapeKeyCode(event.keyCode)) {
				this.show = false

				this.removeEventListeners()
			}
		},

		/**
		 * Handle a global click event outside of the dropdown toggler.
		 *
		 * @return {void}
		 */
		onOutsideClick() {
			if (this.show) {
				this.show = false

				this.removeEventListeners()
			}
		},

		/**
		 * Handle the dropdown click event.
		 *
		 * @return {void}
		 */
		onToggleClick() {
			this.show = !this.show

			this.show ? this.addEventListeners() : this.removeEventListeners()
		},

		/**
		 * Handle the on type change click event.
		 *
		 * @return {void}
		 */
		async onTypeChange() {
			try {
				this.applyingTypeChange = true
				const response = await this.update({
					sid: this.call.sid,
					types: [this.newType],
				})

				this.show = false
				this.applyingTypeChange = false
				this.$alert.response(response)

				const payload = {
					fromFilters: true,
					callId: this.$route.params.sid,
				}
				await this.getCalls(payload)
				await this.getCallTypes()
			} catch (e) {
				this.$alert.response(e)
			} finally {
				this.applyingTypeChange = false
			}
		},

		/**
		 * Remove the global event listeners.
		 *
		 * @return {void}
		 */
		removeEventListeners() {
			document.removeEventListener('keydown', this.onEscapeKeyDown)
			document.removeEventListener('click', this.onOutsideClick)
		},
		...mapActions({ getCallTypes: 'callTypes/get' }),
		...mapActions('calls', {
			getCalls: 'get',
			update: 'update',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'CallTypesDropdown',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The call to be updated.
		 *
		 * @return {Object}
		 */
		call: {
			type: Object,
			required: true,
		},

		/**
		 * The available call types.
		 *
		 * @return {Object}
		 */
		callTypes: {
			type: Array,
			required: true,
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the call for changes.
		 *
		 * @return {void}
		 */
		call() {
			const types = this.call.types

			if (!types || types.length === 0) {
				return
			}

			this.newType = types[0].id
		},
	},

	/**
	 * The component's before destroy lifecycle hook.
	 *
	 * @return {void}
	 */
	beforeDestroy() {
		this.removeEventListeners()
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			show: false,
			newType: null,
			applyingTypeChange: false,
		}
	},
}
</script>
